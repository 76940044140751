@use '../abstracts/' as *;

.header-cgu {
    background-image: linear-gradient(to top, #343434, #4A4A4A);
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 35px;
  
  
    .logo {
      width: 180px;
      margin-bottom: 25px;
    }
  }
  
  .cgu-title {
    font-size: 30px;
    font-weight: bold;
  
    color: white;
  }
  
  .cgu-section {
    padding-top: 25px;
  
    a {
      text-decoration: underline;
      color: $primary-color-1;
  
    }
  }
  
  .cgu-section__title {
    margin-bottom: 25px;
    font-size: 30px;
    font-weight: bold;
  }
  
  .cgu-section__group {
    margin-bottom: 25px;
  
    &__title {
      margin-bottom: 15px;
      font-size: 20px;
      font-weight: bold;
    }
  }
  
  .cgu-article {
    margin-bottom: 10px;
    font-weight: 18px;
    line-height: 23px;
  
    &--sub { padding-left: 20px; }
  }
  
  .font-bold { font-weight: bold; }