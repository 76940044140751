@use "../abstracts/" as *;

.history-title {
  margin: 35px 0;

  font-size: $fs-h2;
  font-weight: bold;

  color: $accent-color-1;
}

.history-list {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
}

.history-entry {
  display: grid;
  grid-template-columns: 10% 25% 15% 15% 25% 10%;

  &.history-entry-establishments {
    grid-template-columns: 5% 25% 30% 15% 10% 10%;
  }

  padding: 20px 20px;

  border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  color: $accent-color-1;

  font-size: $fs-h4;
  font-weight: 400;

  & > * {
    flex: 1;
  }

  .profile {
    display: flex;
    align-items: center;
    font-weight: bold;

    .avatar {
      overflow: hidden;

      width: 40px;
      height: 40px;
      margin-right: 15px;

      border-radius: 50%;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .status {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: block;

      width: 15px;
      height: 15px;
      margin-right: 10px;

      border-radius: 50%;
      background: $secondary-color-1;
    }

    &.online::before {
      background: $green-bar;
    }

    &--2 {
      &::before {
        display: none;
      }

      &.online {
        display: inline-block;
        background: $secondary-color-6;
      }

      .label-bkg {
        padding: 8px;
        border-radius: 3px;

        color: #fff;

        font-size: 0.8em;

        &.complete {
          background: $secondary-color-6;
        }

        &.hold {
          background: $secondary-color-3;
        }

        &.active {
          background: $secondary-color-2;
        }
      }
    }
  }

  .items {
    display: flex;
    align-items: center;

    &.dotsButton {
      background: transparent;
      width: fit-content;
      cursor: pointer;
    }
  }
}

.history-entry2 {
  display: grid;
  grid-template-columns: 23% 33% 13% 33%;

  padding: 20px 20px;

  border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  color: $accent-color-1;

  font-size: $fs-h4;
  font-weight: 400;
}

.history-entry3 {
  display: grid;
  grid-template-columns: 14% 17% 20% 17% 25% 1fr;

  padding: 20px 20px;

  border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  color: $accent-color-1;

  font-size: $fs-h4;
  font-weight: 400;
}

.history-entry5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  padding: 20px 20px;

  border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  color: $accent-color-1;

  font-size: $fs-h4;
  font-weight: 400;

  .profile {
    display: flex;
    align-items: center;
    font-weight: bold;

    .avatar {
      overflow: hidden;

      width: 40px;
      height: 40px;
      margin-right: 15px;

      border-radius: 50%;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .status {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: block;

      width: 15px;
      height: 15px;
      margin-right: 10px;

      border-radius: 50%;
      background: $secondary-color-1;
    }

    &.online::before {
      background: $green-bar;
    }

    &.offline::before {
      background: #ff4e4e;
    }

    &.attente::before {
      background: gray;
    }

    &.cours::before {
      background: $secondary-color-2;
    }

    &.delivered::before {
      background: $green-bar;
    }

    &--2 {
      &::before {
        display: none;
      }

      &.online {
        display: inline-block;
        background: $secondary-color-6;
      }

      .label-bkg {
        padding: 8px;
        border-radius: 3px;

        color: #fff;

        font-size: 0.8em;

        &.complete {
          background: $secondary-color-6;
        }

        &.hold {
          background: $secondary-color-3;
        }

        &.active {
          background: $secondary-color-2;
        }
      }
    }
  }
}

.history-entry6 {
  display: grid;
  grid-template-columns: 10% 25% 15% 15% 25% 10%;

  &.history-entry-establishments2 {
    grid-template-columns: 5% 25% 30% 15% 10% 10%;
  }

  padding: 20px 20px;

  color: $accent-color-1;

  font-size: $fs-h4;
  font-weight: 400;

  .items {
    display: flex;
    align-items: center;

    &.dotsButton {
      background: transparent;
      width: fit-content;
      cursor: pointer;
    }
  }
}

.history-entry4 {
  display: grid;
  grid-template-columns: 13% 15% 20% 20% 26% 10%;

  padding: 20px 20px;

  border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  color: $accent-color-1;

  font-size: $fs-h4;
  font-weight: 400;

  .status {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: block;

      width: 15px;
      height: 15px;
      margin-right: 10px;

      border-radius: 50%;
      background: $secondary-color-1;
    }

    &.online::before {
      background: $green-bar;
    }

    &--2 {
      &::before {
        display: none;
      }

      &.online {
        display: inline-block;
        background: $secondary-color-6;
      }

      .label-bkg {
        padding: 8px;
        border-radius: 3px;

        color: #fff;

        font-size: 0.8em;

        &.complete {
          background: $secondary-color-6;
        }

        &.hold {
          background: $secondary-color-3;
        }

        &.active {
          background: $secondary-color-2;
        }
      }
    }
  }
}

.custom-button-hover {
  background-color: rgb(220, 124, 124);
  color: #fff;
  &:hover {
    background-color: #e22c2c;
    color: 2px solid #fff;
  }
}
.natureButton {
  width: 200px;
  border-radius: 20px;
  &__item {
    width: 150px;
    border-radius: 20px;
  }
}

.input {
  border-bottom: 1px solid gray;
}

.history-entries.__header {
  .history-entry {
    border-top: none;
    border-bottom: 1px solid $border-color;

    font-weight: bold;
    color: rgba($color: #000000, $alpha: 0.4);
  }
  .history-entry2 {
    border-top: none;
    border-bottom: 1px solid $border-color;

    font-weight: bold;
    color: rgba($color: #000000, $alpha: 0.4);
  }
  .history-entry3 {
    border-top: none;
    border-bottom: 1px solid $border-color;

    font-weight: bold;
    color: rgba($color: #000000, $alpha: 0.4);
  }

  .history-entry4 {
    border-top: none;
    border-bottom: 1px solid $border-color;

    font-weight: bold;
    color: rgba($color: #000000, $alpha: 0.4);
  }

  .history-entry5 {
    border-top: none;
    border-bottom: 1px solid $border-color;

    font-weight: bold;
    color: rgba($color: #000000, $alpha: 0.4);
  }
  .item1 {
    padding-left: 63px;
  }
  .item2 {
    padding-left: 88px;
  }
  .item3 {
    padding-left: 48px;
  }
  .item4 {
    padding-bottom: 20px;
  }
}

.history-code {
  flex: unset;

  width: 100px;

  font-size: 50px;
  font-weight: bold;

  color: $accent-color-1;
}
