.grid { display: grid; }

.grid--dashboard {
    width: 100%;
    padding-top: 70px;

    grid-template-columns: 312px calc(100% - 312px);
}

.grid--gap2 {
    grid-template-columns: 2fr 2fr;
    grid-gap: 61px;
}