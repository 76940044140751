// Default container
.container {
    position: relative;
    
    width: 100%;

    &--main {
        max-width: 1050px;
        padding: 0 25px;
        margin: 0 auto;
    }
    
    &--dashboard { padding: 93px 185px 100px 32px ; }

    &.dashboard-header {
        display: flex; 
        flex-direction: row; 
        align-items: center;
        margin-bottom: 50px;
    }
}

.flex-double {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.no-center { align-items: flex-start; }

    &.no-space { justify-content: flex-start;}

    &__l, &__r { width: 50%; }

    &__l { padding-right: 20px; }

    &__r { padding-left: 20px; }

    &__l.__l { text-align: left; }

    &__r.__r {
        display: flex;
        justify-content: flex-end;
        text-align: right;
    }
}

.w-35 { width: 35% !important; }
.w-65 { width: 65% !important; }

