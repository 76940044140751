@use '../abstracts/' as *;

html,
body {
    font-family: $text-font-stack;
    font-weight: 400;
    font-size: 1.125rem;
}

h1,
h2,
h3 { font-family: 'Work Sans', sans-serif; }

a { text-decoration: none; }

.dashboard-title {

    font-size: $fs-big;
    font-weight: bold;
    line-height: .8;
    margin-bottom: 0;
    color: $primary-color-2;

    &__sub-1 {
        font-size: 30px;
        font-weight: 500;
        padding-bottom: 30px;
    }

    &__sub-2 {
        font-size: $fs-h2;
        font-weight: bold;
    }

}
.dashboard-title2 {

    font-size: $fs-big;
    font-weight: bold;
    line-height: .8;
    color: $primary-color-2;

    &__sub-1 {
        font-size: 30px;
        font-weight: 500;
        padding-bottom: 10px;
    }

    &__sub-2 {
        font-size: 20px;
        font-weight: 500;
        line-height: .8;
        color: $primary-color-2;
    }

   

}

.text-left { text-align: left; }

.text-right { text-align: right; }
