@use "../abstracts/" as *;

.dahboardmenu {
  &--item {
    display: flex;
    width: 100%;
    padding: 20px;
    &:hover {
      background-color: $primary-color-fade;
      transition: transform 0.1s; /* Animation */
      transform: scale(1.08);
    }

    &__title {
        margin-top: 5px;
      font-family: "Segoe UI Regular", sans-serif;
      font-size: $fs-h4;
      color: rgba($color: #000000, $alpha: 0.7);
      &:hover {
        color: #ffffff;
      }
    }
  }
}

.card {
  position: relative;
  width: 100%;
}

.card-medical {
  margin-bottom: 20px;
  padding-bottom: 20px;
  overflow: hidden;

  border: 1px solid #b9b9b9;
  background-color: white;
  box-shadow: 0px 0px 15px #0000000d;
  border-radius: 10px;

  &__title {
    height: 25px;
    margin: 0;
    padding: 0 20px;

    background: $secondary-color-1;
    color: white;

    font-size: $fs-h5;
    text-align: center;
    line-height: 25px;

    &.purple {
      background: $secondary-color-5;
    }
    &.yellow {
      background: $secondary-color-2;
    }
    &.black {
      background: $secondary-color-3;
    }
  }

  &__bd {
    padding: 10px 25px;

    &.sub-head {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    }
  }

  &__avatar {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    overflow: hidden;

    border-radius: 2px;
    background-color: gray;
  }

  &__text-tip {
    display: block;
    margin: 0;

    &.__big {
      font-family: "Segoe UI Regular", sans-serif;
      font-size: $fs-h4;
    }
    &.__big-w {
      font-size: 17px;
    }
    &.__fade {
      color: rgba($color: #000000, $alpha: 0.5);
      font-size: 12px;
    }
  }

  .ticket-btn {
    width: 208px;
    height: 40px;

    text-align: center;
    line-height: 40px;
    font-weight: bold;

    border-radius: 10px;

    &-number {
      color: $primary-color-1;

      border: 2px solid $primary-color-fade;
    }

    &-status {
      color: white;
      background-color: #5a6168;
    }
  }
}

.flex-double {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.no-center {
    align-items: flex-start;
  }

  &__l,
  &__r {
    width: 50%;
  }

  &__l {
    padding-right: 20px;
  }

  &__r {
    padding-left: 20px;
  }

  &__l.__l {
    text-align: left;
  }

  &__r.__r {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }
}

.progress-bar {
  position: relative;

  &__container {
    display: flex;
    align-items: center;

    width: 100%;
    height: 5px;
    background-color: #e5e5e5;
  }

  &__icon {
    width: 15px;
    height: 15px;
    margin-left: -5px;

    border-radius: 50%;
    background-color: #e5e5e5;

    &.completed {
      background-color: $green-bar;
    }
  }

  &__progress {
    width: 100%;
  }

  &__progress__inner {
    position: absolute;
    top: 0;
    left: 0;

    width: 50%;
    height: 100%;
    border-radius: 5px;

    background-color: $green-bar;
  }
}

.mapspace {
  width: 100%;
  height: 250px;
  margin-bottom: 25px;
  background-color: rgba($color: #000000, $alpha: 0.2);

  border-radius: 10px;
}

.box-card {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;

  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  box-shadow: $tidy-shadow;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 70px;
    padding: 0 20px;

    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
  }

  .t-button {
    width: 100%;
    height: 40px;
    padding: 0 20px;

    text-align: center;
    line-height: 40px;

    border-radius: 5px;
    background: $secondary-color-2;
  }

  .__list {
    height: 350px;
    padding: 25px 0 0 0;
    overflow-y: scroll;
    overflow-x: hidden;

    &.no-footer {
      height: 400px;
    }

    .__item {
      padding: 15px 25px;
    }
  }

  .__icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: gray;
  }

  .__text {
    width: calc(100% - 20px);
    padding-left: 10px;
  }

  &__footer {
    color: white;
    text-align: center;
    height: 50px;
    line-height: 50px;
    background: $green-bar;
  }

  .products-columns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-child {
      padding-top: 0;
    }

    &.__item {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
      &:last-child {
        border: none;
      }
    }

    .first {
      width: 50%;
    }

    .second,
    .third {
      width: 25%;
    }
  }
}

.archive-boxes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.archive-box {
  position: relative;
  width: 190px;
  height: 200px;
  margin-bottom: 15px;

  background-color: white;
  border-radius: 10px;
  border: 1px solid rgba($color: #000000, $alpha: 0.2);

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    background-color: gray;

    transform: translate(-50%, -50%);
  }

  &__label {
    position: absolute;
    bottom: 0;

    width: 100%;
    padding: 10px;

    text-align: center;
    font-weight: bold;
  }
}
