@use "../abstracts/" as *;

.searchbar-box {
  flex: 3;
  position: relative;
  overflow: hidden;

  width: 100%;
  //max-width: 649px;
  height: 47px;

  background-color: white;
  border-radius: 10px;
  border: 1px solid $border-color;

  input {
    width: 100%;
    height: 47px;
    padding: 15px;

    background-color: white;
  }

  .search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.archive-toolbar {
  margin-bottom: 22px;
  .tools-grp {
    //flex: 1;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .__label {
    margin-right: 10px;
    color: $accent-color-1;
    font-size: $fs-h4;
  }

  .tools-one {
    display: flex;
    align-items: center;
  }

  .tools-two {
    display: flex;
    align-items: center;

    margin-left: 15px;
    padding-left: 15px;

    border-left: 1px solid $border-color;
  }
}

.filter-icon {
  margin-right: 10px;
}

.form {
  &-reminder {
    width: 100%;
    max-width: 746px;
    margin-left: auto;
    margin-right: auto;
    padding: 56px 92px;

    background-color: white;

    border-radius: 10px;
    border: 1px solid $border-color;
    box-shadow: 0px 10px 15px #0000000d;
  }

  &-settings {
    width: 100%;
    padding: 40px 40px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid $border-color;
    box-shadow: 0px 10px 15px #0000000d;
  }

  &-title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 43px;
    font-weight: bold;
    font-size: $fs-h2;

    color: #3c3c3c;

    &::after {
      content: "";
      display: block;
      flex: 1;

      margin-left: 10px;
}

    &.__full {
      &:after {
        margin: 0;
      }
    }

    &.__middle {
      display: block;
      width: 100%;
      text-align: center;
      &::after {
        display: none;
      }
    }
  }
}

.input-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding-bottom: 50px;

  .input-group {
    width: calc(50% - 20px);
  }

  &.__full {
    width: 100%;
    .input-group {
      width: 100%;
    }
  }

  &.__middle {
    justify-content: center;
  }

  .form-label {
    display: block;
    margin-bottom: 20px;

    font-size: $fs-h4;

    color: #adaeb8;
  }

  .form-input {
    width: 100%;
    height: 50px;
    padding: 10px;

    background: #ffffff 0% 0% no-repeat padding-box;

    border: 1px solid #cdcdcd;
    border-radius: 8px;

    &.textarea {
      width: 100%;
      min-height: 115px;
    }
  }


}

.input-group.input-switch {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .form-input {
    width: 0;
    height: 0;
  }

  .form-label {
    margin-bottom: 0;
    color: #3c3c3c;
  }

  div {
    position: relative;

    width: 32px;
    height: 15px;
    background: #e5e5e5 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;

    &::after {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      content: "";
      width: 20px;
      height: 20px;

      border-radius: 50%;
      background: transparent
        linear-gradient(0deg, #ff2121 0%, #ff2222 0%, #ff6565 100%) 0% 0%
        no-repeat padding-box;
      box-shadow: 0px 3px 6px #7a9ea429;
    }
  }
}

.input-group-inline-check {
  display: flex;
  justify-content: space-between;

  .input-section {
  }

  .form-label {
    padding-left: 10px;
  }

  .input-group.input-switch {
    justify-content: flex-start;
    margin-left: 75px;

    div {
      width: 20px;
      height: 20px;

      border-radius: 50%;

      &::after {
        left: 50%;

        width: 15px;
        height: 15px;

        transform: translate(-50%, -50%);
      }
    }
  }
}

.input-group.submit {
  width: 100%;

  .button {
    margin: 0;
  }
}

.form_profile {
  display: flex;
  align-items: center;

  .form_avatar {
    width: 100px;
    height: 100px;
    overflow: hidden;

    margin-right: 20px;

    border-radius: 50%;

    img {
      width: 100%;
    }
  }

  .__name {
    font-weight: bold;
  }
}

.form_header {
  margin-bottom: 50px;
}

.square {
  width: 100px;
  height: 100px;

  background-color: $bkg-body-color;
}

.form-input-check {
  width: 15px;
  height: 15px;

  accent-color: $primary-color-1;
}

.form-etablishement-profile {
  .input-group-check {
    margin: 0;
    padding: 0;

    .input-group {
      width: 100%;
      padding: 25px 0;

      display: flex;
      align-items: center;
      justify-content: space-between;

      border-bottom: 1px solid $border-color;

      .form-label {
        margin: 0;
      }
    }
  }
}
