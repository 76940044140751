@use "../abstracts/" as *;

@forward "container";
@forward "wrapper";
@forward "grid";
@forward "header";
@forward "footer";

.dahboardheader {
  position: fixed;
  z-index: 99;

  width: 100%;
  height: 70px;
  padding: 0 8.125rem;

  background-color: $primary-color-3;
  box-shadow: 0px 0px 15px #0000000d;

  &__wrapper {
    height: 70px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      display: flex;
      align-items: center;

      height: 70px;
      margin-bottom: 0;
    }
  }

  &__logo2 {
    font-family: "Segoe UI Regular", sans-serif;
    font-size:larger;
    padding-top: 25px;
    padding-left: 10px;
    font-weight: bold;
  }


  &__logo3 {
    font-family: "Segoe UI Regular", sans-serif;
    font-size:30px;
    padding-top: 80px;
    padding-left: 10px;
    font-weight: bold;
  }

  &__logo {
    width: 100px;
    height: 20px;

    background-color: rgba($color: #000000, $alpha: 0.5);
  }

  &__nav {
    margin: 0 50px 0 0;
    height: 70px;

    display: flex;
    align-items: center;
  }

  &__nav__item {
    display: flex;
    align-items: center;

    margin-left: 15px;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 40px;
      height: 40px;

      border-radius: 50%;
      border: 1px solid #adaeb8;
      background-color: #ececec;

      &.active {
        border: none;
        background-color: $primary-color-1;
      }

      &.simple {
        border: none;
        background-color: transparent;
      }

      img {
        display: block;
      }
    }

    &__title {
      //font-family:'Segoe UI Bold', sans-serif;
      padding-left: 10px;
      color: $accent-color-1;
    }
  }

  &__log {
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    .dahboardheader__nav__item__icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-left: 10px;
    }
  }
}

// The sidebar menu in the dashboard
.side-bar {
  width: 312px;
  height: 100%;

  background-color: white;
  box-shadow: 0px 0px 15px #0000000d;
}

.archive-hierarchy__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #f2f2f2;
  border-bottom: 2px solid #cfcfcf;

  &:hover {
    background-color: $primary-color-fade;
    transition: transform 0.1s; /* Animation */
    transform: scale(1.08);
  }

  .label-group {
    display: flex;
    align-items: center;
    &:hover {
      color: #ffffff;
    }

    img {
      display: block;
      margin-right: 15px;
    }
  }

  &.__sub {
    padding-left: 60px;
    border-radius: 0;
  }
}

.archive-box-list__item {
  display: flex;
  align-items: center;

  padding: 30px 60px;

  border-bottom: 2px solid #cfcfcf;
  color: $primary-color-2;

  font-size: 15px;

  &.__header {
    padding-top: 15px;
    padding-bottom: 15px;

    color: #adaeb8;
    border-bottom-color: #b9b9b9;
  }

  & > * {
    flex: 1;
  }

  .name {
    display: flex;
    align-items: center;
  }
}
