@use '../abstracts/' as *;

.wrapper {
    position: relative;

    width: 100%;
    min-height: 100vh;
}

.wrapper--light {
    background-color: $bkg-body-color;
}
