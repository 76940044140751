@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Work+Sans:wght@300;400;500;600&display=swap");
.container-mp {
  padding-inline: 20%;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(226, 213, 46, 1) 0%,
    rgba(255, 51, 0, 0.6946428229494923) 100%
  );
}
h1 {
  font-size: 50px;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
}

h2 {
  font-size: 30px;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}

h3 {
  font-size: 20px;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
}

p,
li {
  padding-bottom: 20px;
  line-height: 40px;
  font-family: "Open Sans", sans-serif;
}

.logo {
  width: auto;
  height: 200px;
}
