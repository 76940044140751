@use '../abstracts/' as *;

.button {
    display: inline-flex;
    height: 50px;
    padding: 0 22px;
  
    border-radius: 293px;

    font: normal 300 16px 'Open Sans';
    text-transform: uppercase;
    text-decoration: none;
    line-height: 50px;
  
    cursor: pointer;
    transition: all .5s;
  
    &--default {
      display: inline-block;
      width: 190px;
      height: 50px;
      
      background-color: $primary-color-fade;
      color: white;
      box-shadow: 0px 0px 15px #0000000D;
      border-radius: 10px;

      text-transform: initial;
      text-align: center;
    }

    &--gr {
      background-image: linear-gradient(to right, #E22C2C, #FF6565);
      color: white;
  
      &:hover {
        background-image: none;
        background-color: white;
        color: $primary-color-1;
        cursor: pointer;
      }
    }
  
    &--light {
      background-color: white;
      color: $primary-color-1;
    }
  
    &--wIcon {
      display: flex;
      align-items: center;
      height: 40px;
      line-height: initial;
      background-color: transparent;
      border: 1px solid white;
      color: #F3F3F3;
    
      & > * { padding: 0 5px; }
    }
    
   
  }