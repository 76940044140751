@use "../abstracts/" as *;

.card {
  position: relative;
  width: 100%;
}

.menu {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #ff4e4e;
  width: 300px;
  border-radius: 20px;

  &__title {
    font-size: 30px;
    font-weight: 500;
    font-family: "Work Sans", sans-serif;
    width: 100%;
    color: white;
    line-height: 48px;
    text-align: center;
  }

  &__optionHover {
    cursor: pointer;
    transition: all 0.2s;
  }
}

.map-container {
  height: 300px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 25px;
}

.menu2 {
  position: absolute;
  top: 17%;
  left: 50%;
  background: #ff4e4e;
  width: 500px;
  height: 500px;
  border-radius: 20px;
  overflow: scroll;

  &__subtitle {
    color: "white";
    padding-right: 20;
    padding-top: 15;
  }

  &__title {
    font-size: 30px;
    font-weight: 500;
    font-family: "Work Sans", sans-serif;
    width: 100%;
    color: white;
    line-height: 48px;
    text-align: center;
  }

  &__subtitle {
    font-family: "Work Sans", sans-serif;
    text-align: left;
    color: white;
    font-size: 20px;
    padding-right: 10px;
    padding-top: 5px;
  }

  &__optionHover {
    cursor: pointer;
    transition: all 0.2s;
  }
}

.menu3 {
  position: absolute;
  top: 33%;
  left: 61%;
  background: #ff4e4e;
  width: 500px;
  border-radius: 20px;

  &__subtitle {
    color: "white";
    padding-right: 20;
    padding-top: 15;
  }

  &__title {
    font-size: 30px;
    font-weight: 500;
    font-family: "Work Sans", sans-serif;
    width: 100%;
    color: white;
    line-height: 48px;
    text-align: center;
  }

  &__subtitle {
    font-family: "Work Sans", sans-serif;
    text-align: left;
    color: white;
    font-size: 20px;
    padding-right: 10px;
    padding-top: 5px;
  }

  &__optionHover {
    cursor: pointer;
    transition: all 0.2s;
  }
}

.hover {
  cursor: pointer;
  transition: all 0.2s;

  &__right {
    justify-self: end;
    display: flex;
  }
}

.Container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
}

.card--medical {
  position: relative;

  margin-bottom: 20px;

  &__wrapper {
    padding-bottom: 20px;
    overflow: hidden;

    border: 1px solid #b9b9b9;
    background-color: white;
    box-shadow: 0px 0px 15px #0000000d;
    border-radius: 10px;
  }

  &__menu {
    position: absolute;
    right: 30px;
    top: -140px;
    z-index: 33;

    width: 201px;
    overflow: hidden;

    background: var(--unnamed-color-ff4e4e) 0% 0% no-repeat padding-box;
    background: #ff4e4e 0% 0% no-repeat padding-box;
    border-radius: 10px;
    box-shadow: $tidy-shadow;

    &__option {
      width: 100%;
      height: 48px;

      color: white;

      line-height: 48px;
      font-weight: 300;
      text-align: center;

      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background: $primary-color-2;
      }
    }
  }

  &__text-tip {
    display: block;
    margin: 0;

    font-size: $fs-h4;
    //text-transform: uppercase;

    color: $primary-color-2;

    &__big {
      font-family: $text-font-stack;
      font-size: $fs-h4;
      font-weight: bold;
    }

    &__big-w {
      font-size: 17px;
    }

    &__fade {
      color: rgba($color: #000000, $alpha: 0.5);
    }
  }

  &__title {
    height: 25px;
    margin: 0;
    padding: 0 20px;

    background: $secondary-color-1;
    color: white;

    font-size: $fs-h5;
    font-weight: 400;
    text-align: center;
    line-height: 25px;

    &.purple {
      background: $secondary-color-5;
    }
    &.yellow {
      background: $secondary-color-2;
    }
    &.black {
      background: $secondary-color-3;
    }
  }

  &__profile {
    display: flex;
    justify-content: flex-end;
  }

  &__bd {
    padding: 10px 25px;

    &.sub-head {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    }
  }

  .ticket-btn {
    width: 208px;
    height: 40px;

    text-align: center;
    line-height: 40px;
    font-size: 1rem;
    font-weight: bold;

    border-radius: 10px;

    cursor: pointer;

    &-number {
      color: $primary-color-1;
      border: 2px solid $primary-color-fade;
    }

    &-status {
      color: white;
      background-color: $accent-color-1;
    }
  }
}

.progress-bar {
  position: relative;

  &__container {
    display: flex;
    align-items: center;

    width: 100%;
    height: 5px;
    background-color: #e5e5e5;
  }

  &__icon {
    width: 15px;
    height: 15px;
    margin-left: -5px;

    border-radius: 50%;
    background-color: #e5e5e5;

    &.completed {
      background-color: $green-bar;
    }
  }

  &__progress {
    width: 100%;
  }

  &__progress__inner {
    position: absolute;
    top: 0;
    left: 0;

    width: 50%;
    height: 100%;
    border-radius: 5px;

    background-color: $green-bar;
  }
  &__progress__inner100 {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    border-radius: 5px;

    background-color: $green-bar;
  }
}

.progress-bar2 {
  position: relative;

  &__container {
    display: flex;
    align-items: center;

    width: 100%;
    height: 5px;
    background-color: #e5e5e5;
  }
  &__container2 {
    display: flex;
    align-items: center;

    width: 100%;
    height: 5px;
    background-color: $green-bar;
  }

  &__icon {
    width: 15px;
    height: 15px;
    margin-left: -5px;

    border-radius: 50%;
    background-color: #e5e5e5;

    &.completed {
      background-color: $green-bar;
    }
  }

  &__progress {
    width: 100%;
  }

  &__progress__inner {
    position: absolute;
    top: 0;
    left: 0;

    width: 50%;
    height: 100%;
    border-radius: 5px;

    background-color: $green-bar;
  }
  &__progress__inner2 {
    position: absolute;
    top: 0;
    left: 0;

    width: 50%;
    height: 100%;
    border-radius: 5px;

    background-color: #e5e5e5;
  }
  &__progress__inner100 {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    border-radius: 5px;

    background-color: $green-bar;
  }
}

.mapspace {
  width: 100%;
  height: 250px;
  margin-bottom: 25px;
  background-color: rgba($color: #000000, $alpha: 0.2);

  border-radius: 10px;
}

.box-card {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;

  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  box-shadow: $tidy-shadow;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 70px;
    padding: 0 20px;

    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
  }

  .t-button {
    width: 100%;
    height: 40px;
    padding: 0 20px;

    text-align: center;
    line-height: 40px;

    border-radius: 5px;
    background: $secondary-color-2;
  }

  .__list {
    height: 350px;
    padding: 25px 0 0 0;
    overflow-y: scroll;
    overflow-x: hidden;

    &.no-footer {
      height: 400px;
    }

    .__item {
      padding: 15px 25px;
    }
  }

  .__icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: gray;
  }

  .__text {
    width: calc(100% - 20px);
    padding-left: 10px;
  }

  &__footergreen {
    color: white;
    text-align: center;
    height: 50px;
    line-height: 50px;
    background: $green-bar;
  }

  .products-columns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-child {
      padding-top: 0;
    }

    &.__item {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
      &:last-child {
        border: none;
      }
    }

    .first {
      width: 50%;
    }

    .second,
    .third {
      width: 25%;
      text-align: center;
    }
  }
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffad65;
  padding-right: 20px;
}
.dot2 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ff2121;
  padding-right: 20px;
}
.dot3 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #9b2297;
  padding-right: 20px;
}

.box-card2 {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;

  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  box-shadow: $tidy-shadow;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
  }

  .t-button2 {
    width: 100%;
    height: 50px;
    color: white;
    text-align: center;
    line-height: 50px;
    background: $secondary-color-2;

    &__green {
      width: 100%;
      height: 50px;
      color: white;
      text-align: center;
      line-height: 50px;
      background: $green-bar;
    }

    &__gray {
      width: 100%;
      height: 50px;
      color: white;
      text-align: center;
      line-height: 50px;
      background: gray;
    }
  }

  .__list {
    height: 365px;
    padding: 25px 0 0 0;
    overflow-y: scroll;
    overflow-x: hidden;

    &.no-footer {
      height: 400px;
    }

    .__item {
      padding: 15px 25px;
    }
  }

  .__icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: gray;
    padding-right: 20px;
  }
  .__icongreen {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $green-bar;
    padding-right: 20px;
  }

  .__text {
    width: calc(100% - 20px);
    padding-left: 10px;
  }

  &__footer {
    color: white;
    text-align: center;
    height: 50px;
    line-height: 50px;
    background: $green-bar;
  }
  &__footer2 {
    color: white;
    text-align: center;
    height: 50px;
    line-height: 50px;
    background: gray;
  }
  &__footer3 {
    color: white;
    text-align: center;
    height: 50px;
    line-height: 50px;
    background: $secondary-color-2;
  }

  .products-columns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-child {
      padding-top: 0;
    }

    &.__item {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
      &:last-child {
        border: none;
      }
    }

    .first {
      width: 50%;
    }

    .second,
    .third {
      width: 25%;
      text-align: center;
    }
  }
}

.flex-double2 {
  display: flex;
  align-items: center;
}

.archive-boxes {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  width: calc(100% + 45px);

  &.no-files {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 679px;

    border: 1px dashed #bebebe;
    border-radius: 10px;
  }
}

.archive-box {
  position: relative;

  width: 190px;
  height: 200px;
  margin-bottom: 15px;
  margin-right: 32px;

  background-color: white;
  border-radius: 10px;
  border: 1px solid $border-color;

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }

  &__label {
    position: absolute;
    bottom: 0;

    width: 100%;
    padding: 10px;

    text-align: center;
    font-size: $fs-h5;
    font-weight: 500;

    color: $primary-color-2;
  }
}
