$text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

// Fonts sizes
$fs-big: 2.8125rem;
$fs-h1: 1.25rem; //20px
$fs-h2: 1.125rem; //18px
$fs-h4: 0.95rem;
$fs-h5: 0.75rem;
$fs-h6: 0.625rem; //10px

// Text Colors
$text-color-light: #7E7E7E;
$text-color-dark: #000000;
$text-color-gray: #5A6168;

// Site Base Colors
$primary-color-1: #FF2222;
$primary-color-2:#3C3C3C;
$primary-color-3:#FFFFFF;
$primary-color-fade: #FF4E4E;
$green-bar: #6dbe42;
$bkg-body-color: #F7F7F7;
$accent-color-1: #5A6168;

$secondary-color-1: linear-gradient(180deg, #EC2E2E 0%, #FF4747 100%);
$secondary-color-2: linear-gradient(180deg, #FF6D21 0%, #FFAD65 100%);
$secondary-color-3: linear-gradient(180deg, #646464 0%, #989898 100%);
$secondary-color-5: linear-gradient(180deg, #AC33AC 0%, #9B2297 100%);
$secondary-color-6: linear-gradient(180deg, #5EAC3D 0%, #80D647 100%);
$border-color: rgba($color: #000000, $alpha: 0.20);

// Main shadows
$tidy-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;


/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;


