@use '../abstracts/' as *;

.avatar {
    position: relative;
    overflow: hidden;

    &--circle { border-radius: 50%; }

    &--1x { @include square(1); }
    &--2x { @include square(2); }
    &--3x { @include square(3); }
    &--4x { @include square(4); }

    img {
        display: block;
        width: 100%;
    }
}

