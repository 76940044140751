.profile {
    display: flex;
    align-items: center;

    div:first-child {
        text-align: right;
        margin-right: 10px;
    }
    
    div:nth-child(2) { text-align: left;}
}